<template>
  <div
    class="field"
    :class="{ 'is-horizontal' : labelForInput }"
  >
    <div
      v-if="label"
      class="field-label is-normal has-text-left"
    >
      <label
        ref="label"
        class="label"
      >
        {{ label }}
      </label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <div :class="[`select`, inputCssClass]">
            <select
              class="is-shadowless"
              :name="name"
              :id="inputId"
              :disabled="disabledStatus"
              autocomplete="none"
              @blur="onBlur"
              @change="onChange"
              @focus="onFocus"
            >
              <slot></slot>
              <option
                v-for="(option, index) in options"
                :key="'so' + index"
                :value="getOptionValue(option)"
                :selected="isSelected(option)"
                v-text="getOptionText(option)"
              ></option>
            </select>
          </div>
        </div>
        <p v-if="error">
          <span class="help is-danger">
            {{ error }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInputFieldController from '@/components/UI/Form/BaseInputFieldController'
export default {
  name: 'UiSelect',
  extends: BaseInputFieldController,

  props: {
    options: {
      type: Array,
      default: function() {
        return []
      },
    },
    objectTextField: {
      type: String,
      default: '',
    },
    objectValueField: {
      type: String,
      default: '',
    },
    selectorType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      hasPassedValidation: false,
      availableOptions: [],
      // error: false
    }
  },

  methods: {
    getOptionText(option) {
      let output = ''
      let text = this.objectTextField ? option[this.objectTextField] : option
      switch (this.selectorType) {
        case 'time':
          output = this.$options.filters.minutesToTime(text)
          break

        default:
          output = text
          break
      }
      return output
    },

    isSelected(option) {
      return (
        String(
          this.objectValueField ? option[this.objectValueField] : option
        ) === String(this.value)
      )
    },

    getOptionValue(option) {
      return this.objectValueField ? option[this.objectValueField] : option
    },

    onChange(el) {
      this.$emit('input', el.currentTarget.value)
      this.$emit('change', el.currentTarget.value)
    },
  }
}
</script>
